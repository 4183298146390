
























.cbre-input{
    width: 100%;
    display: flex;
    height: 60px;
    position: relative;

    > .bgc{
        border: 1px solid #43876d;
        background-color: #43876d;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 1;
        width: 100%;
        height: 40px;
        transition: all .15s;
    }
    > label{
        white-space: nowrap;
        height: 40px;
        padding: 0 35px;
        background-color: #6abe28;
        color: #fff;
        line-height: 40px;
        font-size: 14px;
        margin-right: 20px;
        position: relative;
        // top: -20px;
        z-index: 2;
    }
    > input{
        width: 100%;
        height: 40px;
        border: none;
        outline: none;
        background-color: transparent;
        color: #fff;
        font-size: 16px;
        position: relative;
        top: 20px;
        z-index: 2;

        &:focus{
            + .bgc{
                background-color: transparent;
            }
        }
    }
}

