



































































































































.canvas-bgs{
    position: relative;
    width: 100%;
    height: 100%;

    #canvas-bg, #canvas-bg2 {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    #canvas-bg{
        z-index: 2;
    }
}

